<template>
  <Header title="Preukaz darcu" />
  <div id="donation-card" class="container">
    <div class="row dl" @click="openBarcode">
      <div class="col-6 dt">Evidenčné číslo</div>
      <div class="col-6 dd">{{evc || '-'}} <i class="bi bi-upc-scan barcode-icon"></i></div>
    </div>
    <div class="row dl">
      <div class="col-6 dt">Meno</div>
      <div class="col-6 dd">{{userData?.Meno || '-'}}</div>
    </div>
    <div class="row dl">
      <div class="col-6 dt">Dom. pracovisko</div>
      <div class="col-6 dd">{{userData?.DomovskePracovisko || '-'}}</div>
    </div>
    <div class="row dl">
      <div class="col-6 dt">Poisťovňa</div>
      <div class="col-6 dd">{{userData?.Poistovna || '-'}}</div>
    </div>
    <div class="row dl">
      <div class="col-6 dt">Počet odberov</div>
      <div class="col-6 dd">{{userData?.PocetOdberov || '-'}}</div>
    </div>
    <div class="row dl bot-border">
      <div class="col-12 dt bot-border">Krvná skupina</div>
      <div class="col-12 dd blood-type">
        <span class="type-base" :class="'type-base-'+userData?.KrvnaSkupinaZaklad">{{userData?.KrvnaSkupinaZaklad || '-'}}</span>
        {{userData?.KrvnaSkupinaPopis.substr(2).trim() || '-'}}
      </div>
    </div>
    <div class="row dl">
      <div class="col-12 dt">Fenotyp</div>
      <div class="col-12 dd fenotype">{{userData?.FenotypAsText || '-'}}</div>
    </div>

    <div class="nts-modal-overlay" v-if="barcodeShown === true" @click="closeBarcode" />
    <div class="nts-modal" :class="barcodeShown === true ? '' : 'd-none'">
      <svg id="barcode" class="mb-3"></svg>
      <input type="submit" class="btn btn-secondary w-100" value="Ok" @click="closeBarcode" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Header from '@/components/Header'
export default {
  name: 'DonationCard',
  components: {
    Header
  },
  data () {
    return {
      evc: localStorage.getItem('evc') || '',
      barcodeShown: false
    }
  },
  beforeMount () {
    this.fetchData()
  },
  mounted () {
    // eslint-disable-next-line no-undef
    JsBarcode('#barcode', this.evc, {
      width: 3,
      height: 70
    })
  },
  watch: {
    '$store.state.isLoggedIn': function () {
      this.fetchData()
    }
  },
  computed: mapState([
    'isLoggedIn',
    'userData'
  ]),
  methods: {
    fetchData () {
      if (this.isLoggedIn) {
        this.userData === null && this.$store.dispatch('fetchUserData')
      }
    },
    openBarcode () {
      this.barcodeShown = true
    },
    closeBarcode () {
      this.barcodeShown = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";
#donation-card {
  position: relative;
  .blood-type {
    text-align: center;
    font-size: rem(20);
    line-height: 30px;
    padding: 15px 0;
    .type-base {
      display: block;
      margin: 0 0 15px 0;
      padding: 0;
      font-size: rem(50);
      line-height: 50px;

      &.type-base-0 {color: var(--bs-yellow);}
      &.type-base-a {color: var(--bs-red);}
      &.type-base-b {color: var(--bs-green);}
      &.type-base-ab {color: var(--bs-blue);}
    }
  }

  .barcode-icon {
    color: var(--primary-color);
    float: right;
  }

  .bot-border {
    border-bottom: 1px solid var(--border-color)
  }

  .nts-modal {
    background-color: var(--bs-white);
  }
}
</style>
